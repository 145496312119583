<template>
  <section id="eventos">
    <event-feed>
      <slot />
    </event-feed>
  </section>
</template>

<script>
  export default {
    name: 'Events',

    components: {
      EventFeed: () => import('@/components/base/EventFeed'),
    },
  }
</script>
